.dnb-header {
  padding: 1rem 0;

  background-color: var(--color-sea-green);
}

.dnb-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dnb-main-title {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: auto;

  text-decoration: none;
}

.dnb-main-title:focus {
  outline: 2px solid var(--color-accent-yellow-30);
}

.dnb-main-title h1 {
  margin: 0;
  height: 0;
  width: 0;

  text-indent: -8000;
  visibility: hidden;
  overflow: hidden;
}

.dnb-main-title__logo {
  font-size: 4.5rem;
}

.dnb-main-title__logo svg path {
  fill: #fff;
}

.dnb-main-title__sub {
  margin-left: 2.5rem;

  font-size: 1.5rem;

  color: #fff;
}

@media screen and (min-width: 45em) {
  .dnb-main-title:hover {
    text-decoration: underline;
    color: #fff;
  }
  .dnb-main-title__logo {
    font-size: 5rem;
  }
  .dnb-main-title__sub {
    font-size: 1.5rem;
  }
}
