.dnb-no-incidents {
  padding: 3rem 0;

  color: var(--color-black-20);
  background-color: rgba(0, 0, 0, 0.3);
}

.dnb-no-incidents h3 {
  margin-bottom: 0.5rem;

  font-weight: normal;
  font-size: var(--font-size-large);
}

.dnb-no-incidents__buttons {
  display: flex;
  flex-wrap: wrap;

  margin: 2rem 0;
}

.dnb-no-incidents__buttons .dnb-status-link {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.dnb-no-incidents__buttons .dnb-status-link:last-of-type {
  margin-right: 0;
}

.dnb-no-incidents .dnb-image-wrapper {
  margin: 0 auto;
  max-width: 40rem;
}
