.dnb-frontpage-components {
  margin-bottom: 3rem;
}

.dnb-frontpage-components .dnb-status-overview {
  max-width: 30rem;
}

@media screen and (min-width: 48em) {
  .dnb-frontpage-components {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .dnb-frontpage-components .dnb-status-overview {
    margin-bottom: 0;
    width: calc(50% - 1rem);
    max-width: none;
  }
  .dnb-frontpage-components .dnb-planned-incidents {
    width: calc(50% - 1rem);
  }
}

@media screen and (min-width: 45em) {
}

@media screen and (min-width: 45em) {
}
