.dnb-status-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin-bottom: 3rem;

  color: var(--color-black-20);
}

.dnb-status-overview .dnb-icon-header {
  margin-bottom: 2rem;

  width: 100%;
}

.dnb-status-overview .dnb-info-circle {
  margin-bottom: 2rem;
}

.dnb-status-overview .dnb-info-circle:first-of-type {
  margin-bottom: 2rem;
}

.dnb-status-overview .dnb-icon-header__icon svg path {
  fill: none;
  stroke: var(--color-black-20);
}

@media screen and (min-width: 48em) {
  .dnb-status-overview .dnb-info-circle {
    width: calc(50% - 1rem);
  }
}
