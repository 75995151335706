.dnb-legend {
  margin: 2rem 0;
}

.dnb-legend ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  padding: 0;

  list-style: none;
}

.dnb-legend ul li {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.dnb-legend ul li:last-of-type {
  margin-right: 0;
}

.dnb-legend .dnb-status-icon {
  margin-right: 0.5rem;
}

.dnb-legend .dnb-status-icon svg {
  display: block;

  width: 1.2rem;
  height: 1.2rem;
}

@media screen and (min-width: 45em) {
  .dnb-legend ul {
    margin: 0 auto;
    max-width: 900px;
  }
  .dnb-legend ul li {
    width: auto;
  }
  .dnb-legend--left ul {
    margin: 0;
    max-width: none;
  }
}
