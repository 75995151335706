.p_incidents-page .dnb-icon-header {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.p_incidents-page .dnb-incidents-info-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin: 0 auto 3rem auto;

  text-align: center;
  max-width: 30rem;
}

.p_incidents-page .dnb-info-circle {
  margin-bottom: 1.5rem;
}

.dnb-indicents-mobile-slider {
  position: relative;
  margin: 0 auto 5rem;
  width: 90%;
}

.dnb-indicents-mobile-slider .slick-dots {
  bottom: -3rem;
}

.dnb-indicents-mobile-slider .slick-dots li button:before {
  color: #fff;
}

.dnb-indicents-mobile-slider .dnb-info-circle {
  margin: 0 auto;
  width: 80%;
  max-width: none;
}

.dnb-indicents-mobile-slider .slick-arrow {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  text-align: center;
}

.dnb-indicents-mobile-slider .slick-arrow svg path {
  fill: var(--color-accent-yellow-30);
}

.dnb-indicents-mobile-slider .slick-arrow .slick-button-icon {
  position: absolute;
  display: flex;
  align-items: center;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  font-size: 3rem;
}
.dnb-indicents-mobile-slider .slick-arrow.slick-prev:before,
.dnb-indicents-mobile-slider .slick-arrow.slick-next:before {
  display: none;
  content: '';
}

.dnb-indicents-mobile-slider .slick-arrow:focus {
  border: 1px solid var(--color-accent-yellow-30);
}

@media screen and (min-width: 55em) {
  .p_incidents-page .dnb-incidents-info-circles {
    /* border: 1px solid pink; */
    max-width: 32rem;
  }
  .p_incidents-page .dnb-info-circle {
    margin-bottom: 3.5rem;
  }
}

@media screen and (min-width: 67em) {
  .p_incidents-page .dnb-incidents-info-circles {
    max-width: none;
  }
}
