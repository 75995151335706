.dnb-historic-incident-card {
  position: relative;

  padding: 1rem 1.5rem 1rem;

  border: 3px solid var(--color-black-20);
  border-radius: 4px;
}

.dnb-card-date {
  display: flex;
  align-items: center;

  font-size: var(--font-size-medium);
}

.dnb-card-date__day {
  margin-right: 0.5rem;
}

.dnb-card-date__day:after {
  content: '.';
}

.dnb-card-text h3 {
  font-weight: normal;
}

.dnb-historic-incident-card .dnb-status-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.dnb-historic-incident-card .dnb-status-icon svg {
  display: block;

  width: 1.5rem;
  height: 1.5rem;
}

.dnb-card-text > span {
  display: block;
}

.dnb-card-text > span > span {
  font-weight: bold;
}

.dnb-historic-incident-card .dnb-status-text-button {
  margin-top: 1rem;
}

.dnb-status-text-button .dnb-status-text-button__icon {
  display: inline-block;

  transition: transform 150ms ease;
  transform: rotate(0deg);
}

.dnb-status-text-button--clicked .dnb-status-text-button__icon {
  transform: rotate(90deg);
}

/* FALLBACK - DEFAULT */
.dnb-historic-incident-card,
.dnb-historic-incident-card-- {
  border-color: var(--color-black-20);
}
.dnb-historic-incident-card h3,
.dnb-historic-incident-card-- h3 {
  color: var(--color-black-20);
}
/*---------*/

/* ALT OK - NONE */
.dnb-historic-incident-card--none {
  border-color: var(--color-summer-green);
}
.dnb-historic-incident-card--none h3 {
  color: var(--color-summer-green);
}
/*---------*/

/* DELVIS DEGRADERT - MINOR */
.dnb-historic-incident-card--minor {
  border-color: var(--color-accent-yellow);
}
.dnb-historic-incident-card--minor h3 {
  color: var(--color-accent-yellow);
}
/*---------*/

/* DELVIS NEDE - MAJOR */
/* HELT NEDE - CRITICAL */
.dnb-historic-incident-card--major,
.dnb-historic-incident-card--critical {
  border-color: var(--color-signal-orange);
}
.dnb-historic-incident-card--major h3,
.dnb-historic-incident-card--critical h3 {
  color: var(--color-signal-orange);
}

.dnb-incident-history {
  width: 100%;
  max-height: 0;

  overflow: hidden;
  visibility: hidden;
}

.dnb-incident-history--open {
  padding-top: 1rem;

  max-height: 2000px;
  visibility: visible;
}

.dnb-incident-history ul {
  padding: 0 0 0 2rem;

  list-style: none;
}

.dnb-incident-history ul li {
  margin-bottom: 1rem;
}

.dnb-incident-history ul li span {
  display: block;
}

.dnb-incident-history ul li .dnb-incident-history__date {
  font-weight: bold;
}

@media screen and (min-width: 45em) {
  .dnb-historic-incident-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .dnb-historic-incident-card .dnb-status-icon {
    position: relative;
    top: auto;
    right: auto;

    margin-right: 1rem;
    /* order: 3; */
  }
  .dnb-card-date {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    width: 10rem;
  }
  .dnb-card-date__day {
    margin-right: 0;

    font-size: 3.5rem;
  }
  .dnb-card-date__day:after {
    content: '';
  }
  .dnb-card-date__month {
    margin-top: 1.5rem;

    font-size: 1.5rem;
  }
  .dnb-card-text {
    margin-left: 1rem;
    width: calc(100% - 14rem);
  }
  .dnb-incident-history {
    padding-left: 11rem;
  }
}
