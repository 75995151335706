.dnb-status-message {
  position: relative;

  padding: 1rem 4rem 1rem 1rem;
  margin-bottom: 1rem;

  border: 3px solid var(--color-summer-green);
  border-radius: 4px;
}

/* FALLBACK - DEFAULT */
.dnb-status-message,
.dnb-status-message-- {
  border-color: var(--color-black-20);
}
.dnb-status-message .dnb-status-message__text,
.dnb-status-message-- .dnb-status-message__text {
  color: var(--color-black-20);
}
/*---------*/

/* ALT OK - NONE */
.dnb-status-message--none {
  border-color: var(--color-summer-green);
}
.dnb-status-message--none .dnb-status-message__text {
  color: var(--color-summer-green);
}
/*---------*/

/* DELVIS DEGRADERT - MINOR */
.dnb-status-message--minor {
  border-color: var(--color-accent-yellow);
}
.dnb-status-message--minor .dnb-status-message__text {
  color: var(--color-accent-yellow);
}
/*---------*/

/* DELVIS NEDE - MAJOR */
/* HELT NEDE - CRITICAL */
.dnb-status-message--major,
.dnb-status-message--critical {
  border-color: var(--color-signal-orange);
}
.dnb-status-message--major .dnb-status-message__text,
.dnb-status-message--critical .dnb-status-message__text {
  color: var(--color-signal-orange);
}
/*---------*/

.dnb-status-message__text {
  display: block;

  margin-bottom: 0.5rem;

  line-height: 1.5rem;
}

.dnb-status-message__time {
  display: block;
}

.dnb-status-message .dnb-status-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media screen and (min-width: 30em) {
  .dnb-status-message {
    padding: 1rem 4rem 1rem 3rem;
  }
  .dnb-status-message__text {
    font-size: var(--font-size-large);
  }
}
