.dnb-info-circle {
  position: relative;

  width: 48%;
  max-width: 14rem;

  border: 2px solid rgba(244, 244, 244, 0.5);
  border-radius: 50%;
}

.dnb-info-circle__filler-square {
  display: block;

  width: 100%;
  height: 100%;
}

.dnb-info-circle__content {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;

  padding: 1.5rem 0;

  width: 100%;
  height: 100%;
}

.dnb-info-circle__top-text {
  display: flex;

  max-width: 12rem;

  font-size: var(--font-size-x-small);
  line-height: var(--font-size-medium);
  text-align: center;
}

.dnb-info-circle__bottom-text {
  display: flex;
  align-items: flex-end;
}
.dnb-info-circle__number {
  margin: 1rem 0;

  font-size: 2.5rem;
  line-height: 1rem;
}

@media screen and (min-width: 35em) {
  .dnb-info-circle__top-text {
    padding: 0 10%;

    font-size: var(--font-size-small);
  }
  .dnb-info-circle__top-text,
  .dnb-info-circle__bottom-text {
    min-height: 2.7rem;
  }
  .dnb-info-circle__number {
    font-size: 3.5rem;
    line-height: 1.5rem;
  }
}
