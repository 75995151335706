.dnb-group {
  padding: 0.5rem 2rem 2rem;
  margin-bottom: 2rem;

  background-color: rgba(0, 0, 0, 0.3);
  color: var(--color-mint-green);
}

.dnb-group .dnb-icon-header__icon svg path {
  fill: none;
  stroke: var(--color-black-20);
}

.dnb-group-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.dnb-group-content__left {
  margin-bottom: 3rem;
}

.dnb-incident-counter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  padding: 0 1rem;
  margin-bottom: 1rem;

  text-align: center;
}

.dnb-incident-counter__number {
  display: block;

  font-size: 5.5rem;
  font-weight: var(--font-weight-medium);
  line-height: 5rem;

  color: var(--color-black-20);
}

.dnb-incident-counter__text {
  color: var(--color-black-20);
  line-height: var(--font-size-medium);
}

.dnb-incident-counter__text,
.dnb-incident-counter__text span {
  display: block;
}

.dnb-status-list ul {
  padding: 0;
  margin: 0;

  list-style-type: none;
}

.dnb-status-list ul li {
  position: relative;
  padding-left: calc(25px + 0.5rem);
  margin-bottom: 0.5rem;
}

.dnb-status-list ul li > span {
  color: var(--color-black-20);
}
.dnb-status-list .dnb-status-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
.dnb-status-list .dnb-icon svg {
  display: block;

  margin: 0;
  width: 1.2rem;
  height: 1.2rem;
}

/* Hendelser i tall og liste ved siden av hverandre. Skjerm større enn ca 500px */
@media screen and (min-width: 30em) {
  .dnb-group {
    padding: 0.5rem 2rem 3rem;
  }

  .dnb-group-content {
    /* display: flex; */
    flex-direction: unset;
    /* align-items: flex-start; */
  }

  .dnb-group-content__right {
    display: inline-block;
    width: 48%;
  }

  .dnb-group-content__left {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 5%;
    max-width: 170px;
  }
  .dnb-incident-counter__number {
    font-size: 4rem;
  }
}

/* Større enn ca 880px. Når gruppene ligger ved siden av hverandre */
@media screen and (min-width: 55em) {
  .dnb-group {
    width: calc(50% - 1rem);
  }
  .dnb-incident-counter__number {
    font-size: 4rem;
  }
}
