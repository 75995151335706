.dnb-footer {
  width: 100%;
}

.dnb-footer__content {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3rem 0 1.5rem;
  border-top: 2px solid rgba(0, 0, 0, 0.4);
}

.dnb-footer .dnb-footer__copyright {
  margin-left: 1rem;
}
