.dnb-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 6rem;
  height: 7rem;
}

.dnb-loading__icon {
  display: block;

  animation: infinite-spinning 3s linear infinite;
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
