.dnb-status-messages-wrapper {
  margin: 0 0 3rem 0;
  padding: 0;

  list-style: none;
}

.dnb-no-statuses {
  padding: 2rem 1rem;
  margin-bottom: 2rem;

  border: 3px solid var(--color-summer-green);
  border-radius: 4px;
}

.dnb-no-statuses h2 {
  margin: 0 0 0.5rem;

  font-weight: normal;

  color: var(--color-summer-green);
}

.dnb-no-statuses__last-updated {
  display: block;

  margin-bottom: 1.5rem;
}

@media screen and (min-width: 45em) {
  .dnb-no-statuses {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dnb-no-statuses__last-updated {
    margin-bottom: 0;
  }
}
