/* Footer i bunn */
.dnb-root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.dnb-root .dnb-footer {
  margin: auto auto 0 auto;
}

/* Overall styling */
body {
  background-color: var(--color-ocean-green);
  color: var(--color-black-20);

  overflow-y: scroll;
}

.dnb-center-content {
  padding: 0 var(--spacing-medium);
  width: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
}

.dnb-image-wrapper {
  padding: 0;
}

.dnb-image-wrapper img {
  display: block;
  width: 100%;
}
/* Statusikoner som brukes mange steder */

/* OPERATIONAL - GRØNT CHECKMERKE */
.dnb-status-icon.dnb-status-icon--none path,
.dnb-status-icon.dnb-status-icon--operational path {
  fill: var(--color-summer-green);
}

/* MINOR - GULT UTROPSTEGN */
.dnb-status-icon.dnb-status-icon--minor_outage path,
.dnb-status-icon.dnb-status-icon--minor path {
  fill: var(--color-accent-yellow);
}

/* MAJOR - RØD VARSELTREKANT */
.dnb-status-icon.dnb-status-icon--major_outage path,
.dnb-status-icon.dnb-status-icon--major path {
  fill: var(--color-signal-orange);
}

/* CRITICAL - RØDT KRYSS */
.dnb-status-icon.dnb-status-icon--critical path {
  fill: var(--color-signal-orange);
}
/* UNDER ARBEID - GRÅ SKIFTENØKKEL */
.dnb-status-icon.dnb-status-icon--under_maintenance path {
  fill: var(--color-black-20);
}

/* Overskrivning av Button fra Eufemia */
.dnb-button.dnb-button--primary.dnb-status-button {
  border: var(--button-border-width) solid var(--color-accent-yellow-30);
  background-color: transparent;
  color: var(--color-accent-yellow-30);
  box-shadow: none;
  transition: background-color 100ms ease;
}

.dnb-button.dnb-button--primary.dnb-status-button.dnb-status-button--active {
  background-color: var(--color-accent-yellow-30);
  color: var(--color-ocean-green);
  box-shadow: none;
}

html:not([data-is-touch]) .dnb-status-button:focus:not([disabled]) {
  background-color: var(--color-accent-yellow-30);
  box-shadow: none;
}
@media screen and (min-width: 30em) {
  html:not([data-is-touch]) .dnb-status-button.dnb-button--primary:hover:not([disabled]) {
    text-decoration: underline;
    background-color: var(--color-accent-yellow-30);
    box-shadow: none;
  }
}

.dnb-status-text-button {
  position: relative;
  padding: 0.5rem 0;

  height: var(--button-height);

  color: var(--color-accent-yellow-30);
  background-color: transparent;
  border: none;
  text-align: left;

  -webkit-appearance: none;
  cursor: pointer;
}

.dnb-status-text-button__text {
  font-size: var(--font-size-small);
}
.dnb-status-text-button__icon {
  margin-left: 0.5rem;
}

.dnb-status-text-button__icon svg {
  margin-left: 0.5rem;

  width: 1.2rem;
  height: 1.2rem;
}

.dnb-status-text-button:after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;
  content: '';
  opacity: 0;

  transition: opacity 100ms ease;
  background-color: var(--color-accent-yellow-30);
}
.dnb-status-text-button :focus:not([disabled]),
html:not([data-is-touch]) .dnb-status-text-button:focus:not([disabled]) {
  padding: 0.5rem;

  outline: 2px solid var(--color-accent-yellow-30);
}

.dnb-status-text-button:active {
  outline: none;
}

@media screen and (min-width: 30em) {
  .dnb-status-text-button:hover:after {
    opacity: 1;
  }
}

/* TODO Focus og hover */
/* Liten, rund knapp */
.dnb-small-button {
  position: relative;

  width: 40px;
  height: 40px;

  text-align: center;

  border: 1px solid var(--color-accent-yellow-30);
  background: none;
  color: var(--color-accent-yellow-30);
  border-radius: 50%;
  cursor: pointer;
}

.dnb-small-button__icon {
}
