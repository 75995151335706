.dnb-planned-incidents {
  color: var(--color-black-20);
}

.dnb-planned-incidents__no-incidents {
  padding-left: calc(25px + 2rem);
}

.dnb-planned-incidents .dnb-icon-header__icon svg path {
  fill: var(--color-black-20);
  stroke: none;
}

.dnb-planned-incidents > ul {
  padding: 0 0 0 1rem;
  margin-top: 2rem;

  list-style: none;
}

.dnb-planned-incidents > ul > li {
  position: relative;

  padding-left: calc(25px + 1rem);
  margin-bottom: 2.5rem;
}

.dnb-planned-incidents > ul li .dnb-round-icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  margin-right: 1rem;
}

.dnb-planned-incidents ul li .dnb-round-icon svg {
  fill: var(--color-ocean-green);
}

.dnb-planned-incidents > ul > li h3,
.dnb-planned-incidents > ul > li h4 {
  margin-bottom: 0;
  font-weight: normal;
}

.dnb-planned-incidents > ul > li p {
  margin-top: 0;
}

.dnb-planned-incident__time {
  font-weight: bold;
}

.dnb-planned-incident__time span {
  font-weight: bold;
}

.dnb-planned-incident__affected-systems {
  padding-left: 1rem;
  margin: 0;
  list-style: c;
}
