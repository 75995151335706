.dnb-history-header h2 {
  font-weight: normal;
}

.dnb-history-header {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  border: 1px solid red;
}

.dnb-history-header h2 .dnb-icon {
  margin-right: 1rem;

  font-size: 3rem;
}
.dnb-history-header__actions {
  display: flex;
  justify-content: space-between;

  margin-bottom: 2rem;
  width: 100%;
}
.dnb-history-header__content {
  display: flex;
  justify-content: space-between;

  padding: 0 1rem;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 30em) {
  .dnb-history-header__content {
    padding: 1rem 2rem;
  }
}
