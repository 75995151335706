.dnb-incidents-list-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 3em;

  background-color: rgba(0, 0, 0, 0.3);
}

.dnb-incidents-list-wrapper__header {
  margin-bottom: 2rem;
}

.dnb-incidents-list-wrapper__header .dnb-button:first-of-type {
  margin-right: 1rem;
}

.dnb-incidents-list-wrapper__title {
  margin-bottom: 1rem;

  font-weight: normal;
}
