.dnb-icon-header {
  display: flex;
  align-items: center;

  margin: var(--spacing-medium) 0;
}

.dnb-icon-header__icon {
  margin-right: var(--spacing-small);
  margin-top: 5px;

  font-size: 2.5rem;
}
.dnb-icon-header h2 {
  display: flex;
  flex-direction: column;

  margin: 0;

  font-size: var(--font-size-medium);
  font-weight: normal;
  line-height: normal;

  color: var(--color-black-20);
}

.dnb-icon-header__subtitle {
  margin-top: 0.5rem;
  font-size: var(--font-size-small);
}

.dnb-icon-header__icon svg path {
  fill: var(--color-black-20);
}

.dnb-icon-header--page-header h2 {
  font-size: var(--font-size-large);
}
