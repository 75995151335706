.dnb-status-link {
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;

  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: auto;
  height: var(--button-height);

  line-height: var(--button-height);
  font-size: var(--font-size-small);
  text-decoration: none;

  background-color: transparent;
  color: var(--color-accent-yellow-30);
  box-shadow: none;
  transition: background-color 100ms ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: var(--button-border-radius);
  border: var(--button-border-width) solid var(--color-accent-yellow-30);
}

.dnb-status-link .dnb-icon {
  margin: 0 calc(var(--button-icon-size) / 2) 0 0;
}

.dnb-status-link--right .dnb-icon {
  order: 2;

  margin: 0 0 0 calc(var(--button-icon-size) / 2);
}

.dnb-status-link--active {
  background-color: var(--color-accent-yellow-30);
  color: var(--color-ocean-green);
  box-shadow: none;
}

html:not([data-is-touch]) .dnb-status-link:focus,
html:not([data-is-touch]) html:not([data-is-touch]) .dnb-status-link:focus {
  background-color: var(--color-accent-yellow-30);
  color: var(--color-ocean-green);
  box-shadow: none;
  outline: none;
}
/* TODO skille mellom hover og focus  */
@media screen and (min-width: 30em) {
  html:not([data-is-touch]) .dnb-status-link:hover {
    text-decoration: underline;
    background-color: var(--color-accent-yellow-30);
    color: var(--color-ocean-green);
    box-shadow: none;
  }
}
